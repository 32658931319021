var modal_contact = document.getElementById('modal-contact');
var modal_video = document.getElementById('modal-video'); 
var demo = document.getElementById("demo");

window.onclick = function(event) {  
    if (event.target == modal_contact) {
      modalClose()
    }
    if(event.target == modal_video){
      modalVideoClose()         
    }
  }


function modalShow(){
  document.getElementById('modal-contact').style.display='block'
}

function modalClose(){
  document.getElementById('modal-contact').style.display='none'
}


 
function modalVideoShow(){
  demo.play();
  document.getElementById('modal-video').style.display='block'
 
}
function modalVideoClose(){
  document.getElementById('modal-video').style.display='none'
  demo.pause();
}
 

// new Glide(".glide", {
//   type: "carousel",
//   perView: 5,
//   gap: 20,
//   autoplay: 2000,
//   breakpoints: {
//     1150: {
//       perView: 4,
//     },
//     850: {
//       perView: 2,
//     },
//     550: {
//       perView: 1,
//     },
//   },
// }).mount();

ScrollReveal().reveal(".service", { delay: 450 });



// let img = document.getElementById('img');
// function testWebP() {
//   return new Promise(res => {
//       const webP = new Image();
//       webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
//       webP.onload = webP.onerror = () => {
//           res(webP.height === 2);
//       };        
//   })
// };
// testWebP().then(hasWebP =>{
//   if(hasWebP){
//     img.src = img.getAttribute('data-webp');
//   }else{
//     img.src = img.getAttribute('data-img');
//   }
// });





 


 